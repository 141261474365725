<template>
    <div class="filters-list tw-oveflow-x-hidden">
        <!-- <div class="tw-sticky tw-top-0 tw-bg-white tw-p-2 tw-z-auto"> -->
        <!-- <neo-input :changeCase="true" :shadow="false" placeholder="Search" borderColor="var(--brand-accent-neutral)" bg="var(--brand-area-color)" margin="0" icon="search" v-model="search" /> -->
        <!-- </div> -->

        <div class="sidebar-treeview tw-pr-2">
            <!-- <div class="tw-flex tw-p-2 tw-justify-between">
                <div class="tw-self-center tw-text-lg">Social Media</div>
                <neo-button :label="toolExpanded ? 'Collapse all' : 'Expand all'" faicon="filter" class="tw-w-30 tw-rounded-lg tw-h-10 tw-self-center" border-radius="0.75rem" :bg="filterVisible ? '#e5effa' : white" :hoverBg="filterVisible ? '#e5effa' : white" hoverText="var(--brand-color)" color="var(--brand-color)" stroke="white" @click="handleExpandAll('tools')" v-if="toolsView"> </neo-button>
            </div> -->

            <!-- Monitoring tab sidebar -->
            <div v-if="toolsView && ($route.name === 'monitoring' || $route.name === 'monitoring-ro')" class="">
                <div class="tool-container tw-overflow-hidden tw-shadow-md tw-rounded-lg tw-border-gray-200 tw-border-solid tw-border-2 tw-border-t tw-border-b-0 tw-mb-4" v-for="tools in toolsMapping" :key="tools.heading">
                    <div class="tools-heading tw-flex tw-p-2 tw-self-center tw-font-semibold tw-text-gray-700">{{ tools.heading }}</div>
                    <treeview :selectedToolsList="selectedToolsList" :heading="true" :items="tools.items" :allowClick="true" @clickEvent="clickEvent" ref="tools" :showChecked="showChecked" @handleChangeGlobal="handleChangeGlobal" @updateFrequency="updateFrequency" @updateMonitoring="updateMonitoring"></treeview>
                </div>
            </div>

            <!-- Tools tab sidebar -->
            <div v-if="toolsView && $route.name !== 'monitoring' && $route.name !== 'monitoring-ro'">
                <template v-if="tools.heading !== 'Manual Monitoring'">
                    <div class="tool-container tw-overflow-hidden tw-shadow-md tw-rounded-lg tw-border-gray-200 tw-border-solid tw-border-2 tw-border-t tw-border-b-0 tw-mb-4" v-for="tools in toolsMapping" :key="tools.heading">
                        <div>
                            <div class="tools-heading tw-flex tw-p-2 tw-self-center tw-font-semibold tw-text-gray-700">{{ tools.heading }}</div>
                            <treeview :selectedToolsList="selectedToolsList" :heading="true" :items="tools.items" :allowClick="true" @clickEvent="clickEvent" ref="tools" :showChecked="showChecked" @handleChangeGlobal="handleChangeGlobal" @updateFrequency="updateFrequency" @updateMonitoring="updateMonitoring" :frequencyInput="frequencyInput" :usersList="usersList" :products="products"></treeview>
                        </div>
                    </div>
                </template>
            </div>
            <div class="tw-text-base tw-text-gray-500 tw-px-2 tw-text-center">
                <template v-if="!toolsView">No Tool found!</template>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/button";
// import Treeview from "@/components/treeview";
import NeoInput from "@/components/input";
import {uniq, cloneDeep} from "lodash";
import {mapGetters} from "vuex";
import {FEATURES} from "@/utils/constants";

// Dynamic imports for components that are not used on first load
const Treeview = () => import("@/components/treeview");

export default {
    text: "CaseFilters",
    components: {
        Treeview,
        "neo-button": Button,
        NeoInput,
    },
    props: {
        tools: {
            type: Object,
            default: {},
        },
        showChecked: {
            type: Boolean,
            default: false,
        },
        selectedToolsList: {
            type: Array,
            default: () => [],
        },
        frequencyInput: {
            type: Boolean,
            default: false,
        },
        usersList: {
            type: Array,
            default: () => [],
        },
        products: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectedFilters: {},
            filterVisible: true,
            toolExpanded: false,
        };
    },

    async mounted() {},

    computed: {
        ...mapGetters(["getPermissions"]),
        filteredTools() {
            let tools = cloneDeep(this.tools);
            let routeName = this.$route.name.replace("-ro", "");
            if (this.$route.name === "tools" || this.$route.name === "tools-ro" || this.$route.name === "monitoring") {
                tools = tools?.children?.filter((ele) => this.getPermissions.find((ele1) => FEATURES[routeName][ele1] == ele.activeProcess));
            } else {
                tools = tools?.children;
            }
            const children = tools?.filter((n) => {
                return ((n.children = n.children?.filter((n2) => n2.text.toLowerCase().includes(this.search?.toLowerCase()))) && n?.children.find((n1) => n1.text.toLowerCase().includes(this.search?.toLowerCase()))) || n.text?.toLowerCase().includes(this.search?.toLowerCase());
            });
            this.handleMaxHeight();
            return {children};
        },

        toolsMapping() {
            let headings = uniq(this.filteredTools?.children?.map((item) => item.heading));
            let tools = [];
            headings.forEach((heading) => {
                tools.push({
                    heading: heading,
                    items: {children: this.filteredTools?.children?.filter((item) => item.heading === heading)},
                });
            });
            return tools;
        },
        toolsView() {
            return this.filteredTools?.children?.length > 0;
        },
        search() {
            return this.$store.getters.search[this.$route.name] || "";
        },
    },

    methods: {
        updateFilters(selectedItems, category) {
            this.selectedFilters[category] = selectedItems;
            this.$emit("updateFilters", this.selectedFilters);
        },
        clickEvent(tree) {
            this.$emit("clickEvent", tree);
        },
        handleExpandAll(reference) {
            let expanded = "";
            this.toolExpanded = !this.toolExpanded;
            expanded = this.toolExpanded;
            this.$refs[reference].$children[0].$children.forEach((ele) => {
                ele.expanded = expanded;
                if (ele.$children.length > 0) {
                    ele.$children.forEach((elem) => {
                        elem.expanded = expanded;
                    });
                }
                [...ele.$el.getElementsByClassName("panel expandible panel-transition")]?.forEach((e) => (e.style.maxHeight = expanded ? "fit-content" : "0"));
            });
        },
        handleMaxHeight() {
            //For expanded panel height update
            [...document.getElementsByClassName("panel expandible panel-transition")].filter((ele) => ele?.style?.getPropertyValue("max-height") && ele?.style?.getPropertyValue("max-height") != "0px")?.forEach((e) => (e.style.maxHeight = "fit-content"));
        },
        handleChangeGlobal(value) {
            this.$emit("handleChangeGlobal", value);
        },
        updateTool(tool) {
            this.$emit("update", tool);
        },
        updateFrequency(data) {
            this.$emit("updateFrequency", data);
        },
        updateMonitoring(data) {
            this.$emit("updateMonitoring", data);
        },
    },
};
</script>
<!--<style src="vuetify/dist/vuetify.min.css" scoped></style>-->

<style lang="scss" scoped>
.filter-section,
.filters-list {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}

.filters-list {
    // height: calc(100% - 150px);
    overflow-y: auto;
    height: 100%;

    .check-container {
        div {
            .checkmark {
                height: 16px !important;
                width: 16px !important;
                top: -7px !important;
                left: -7px !important;
                border-radius: 4px !important;
                border: 1px solid #ccc !important;
                background-color: white !important;

                &:after {
                    top: 0px !important;
                    left: 3px !important;
                    width: 5px !important;
                    height: 9px !important;
                    border-width: 0 3px 3px 0;
                }
            }
        }
    }
}

.sidebar-treeview {
    // height: calc(100% - 10px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}

.accordion-root {
    border-top: 1px solid;
    border-bottom: 1px solid;
    @apply tw-border-gray-200;
}
::v-deep {
    .treeText {
        margin-left: 1.25rem;
    }
}
</style>
